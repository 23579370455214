$z-index: (
  modal : 200,
  navigation : 100,
  footer : 90,
  box: 60,
  background: 50,
  page: 10,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
$aqua50: #d9f3f3;
$aqua100: #96f6f1;
$aqua200: #00efea;
$aqua300: #00e5e5;
$aqua400: #00dce0;
$aqua500: #00d4de;
$aqua600: #00c3cb;
$aqua700: #00adb0;
$aqua800: #008486;
$aqua900: #066667;

$juice50: #fff3e0;
$juice100: #ffe0b2;
$juice200: #ffcd80;
$juice300: #ffb84d;
$juice400: #ffa826;
$juice500: #ff9700;
$juice600: #f88d00;
$juice700: #f67d00;
$juice800: #ef6d00;
$juice900: #e65200;

$lapis50: #efe5fd;
$lapis100: #cec5f4;
$lapis200: #ac9eee;
$lapis300: #8876e8;
$lapis400: #6959e3;
$lapis500: #443cdd;
$lapis600: #3538d7;
$lapis700: #1530ce;
$lapis800: #002bc7;
$lapis900: #001fbd;

$grape50: #f0e5fc;
$grape100: #d7c0f7;
$grape200: #bd95f2;
$grape300: #a167ee;
$grape400: #8b40ea;
$grape500: #7200e5;
$grape600: #6700d7;
$grape700: #5600d7;
$grape800: #4400d2;
$grape900: #1600cb;

$navy50: #e5e5eb;
$navy100: #b9bdcf;
$navy200: #8393ae;
$navy300: #626a8f;
$navy400: #454d7a;
$navy500: #273267;
$navy600: #212c5f;
$navy700: #1a2455;
$navy800: #131b49;
$navy900: #0a0b33;

$dark50: #fdfdfd;
$dark100: #f8f8f8;
$dark200: #f3f3f3;
$dark300: #eaeaea;
$dark400: #cfcfcf;
$dark500: #b2b2b2;
$dark600: #888888;
$dark700: #737373;
$dark800: #535353;
$dark900: #303030;

$bley100: #f4f6fb;
$bley400: #e7ebf5;
$bley500: #d9dde8;
$bley600: #9ea7bf;
$bley700: #697186;
$bley800: #4d556b;

$purewhite: #ffffff;
$white: #fafafc;
//syncfusion theme colours
$theme-primary: $aqua900;
$theme-dark-alt: $aqua900;
$theme-dark: $aqua900;
$theme-darker: $aqua900;

$theme-lighter: $aqua400;
$theme-lighter-alt: $aqua600;

$theme-secondary: $aqua600;
$theme-tertiary: $aqua400;

//ecs additional theme colours
$theme-ecs-background: $bley400;
$theme-ecs-background400: $bley400;
$theme-ecs-background200: $bley100;
$theme-ecs-background-lighten: lighten($theme-ecs-background, 10%);
$theme-ecs-background-darken: darken($theme-ecs-background, 10%);
$theme-ecs-background-light: $purewhite;
$theme-ecs-background-dark: $navy900;
$theme-ecs-background-alt-dark: $bley700;
$theme-ecs-background-alt: $grape900;
$theme-ecs-background-alt-light: $grape50;

$theme-ecs-default: $theme-ecs-background;
$theme-ecs-primary: $theme-primary;
$theme-ecs-secondary: $grape400;
$theme-ecs-tertiary: $juice800;

$theme-ecs-default-dark: $theme-ecs-background-darken;
$theme-ecs-primary-dark: $theme-dark;
$theme-ecs-secondary-dark: $grape500;
$theme-ecs-tertiary-dark: $juice900;

$theme-ecs-clr-light: $purewhite;
$theme-ecs-clr-dark: $navy900;
$theme-ecs-clr-alt: $dark900;
$theme-ecs-clr-transparent-light: $navy100;
$theme-ecs-clr-transparent-dark: $navy200;
$theme-ecs-clr-transparent-alt: $grape500;

$theme-ecs-dimension-xsmall: 0.875rem;
$theme-ecs-dimension-small: 1.625rem;
$theme-ecs-dimension-medium: 2.5rem;
$theme-ecs-dimension-large: 3.0rem;
$theme-ecs-dimension-xlarge: 3.875rem;
$theme-ecs-dimension-xxlarge: 4.625rem;

$theme-ecs-rounddimension-xsmall: 1rem;
$theme-ecs-rounddimension-small: 1.725rem;
$theme-ecs-rounddimension-medium: 2.5rem;
$theme-ecs-rounddimension-large: 3.0rem;
$theme-ecs-rounddimension-xlarge: 4.05rem;
$theme-ecs-rounddimension-xxlarge: 4.825rem;

$font-size: 0.875rem;

// Import theme default styling
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-base/styles/fabric.scss';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-react-grids/styles/fabric.css';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-buttons/styles/fabric.css';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-calendars/styles/fabric.css';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-dropdowns/styles/fabric.css';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-popups/styles/fabric.css';/*** --- ***/
/*** IMPORTED FILE NOT FOUND ***/
@import '~@syncfusion/ej2-inputs/styles/fabric.css';/*** --- ***/

// Custom component styling
.ecs-box {
  &.ecs-banner-notice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 auto;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    transition: box-shadow 0.3s ease-in-out;
    position: absolute;
    z-index: 1000;
    bottom: 50px;
    width: 80vw;
    min-width: 500px;
    left: 50%;
    transform: translateX(-50%);

    .ecs-banner-notice-dismiss:hover {
      cursor: pointer;
    }

    .ecs-banner-notice-text {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 1px;
      font-size: 1rem;
    }

    .ecs-banner-notice-icon {
      color: $aqua900;
      flex-grow: 1;
      max-width: 24px;
    }

    &.ecs-banner-notice-info {
      background-color: $aqua50;

      .ecs-banner-notice-icon {
        color: $aqua900;
      }

      .ecs-banner-notice-text {
        color: $aqua900;
      }
    }

    &.ecs-banner-notice-warning {
      background-color: $juice50;

      .ecs-banner-notice-icon {
        color: $juice900;
      }
      .ecs-banner-notice-text {
        color: $juice900;
      }
    }
  }
}

.ecs-banner {
  fill: $purewhite;
}
.ecs-box {
  font-family: 'Avenir Medium';
  display: flex;
  flex-direction: column; //default
  flex: 1 1 auto;
  overflow: hidden;
  @include z-index(box);

  &.ecs-box-row {
    flex-direction: row;
  }

  &.ecs-rounded-round {
    align-items: center;
    justify-content: center;
  }

  &.ecs-box-blur {
    $bluropacity: 0.85;

    &.ecs-background-default {
      background: rgba($theme-ecs-background, $bluropacity);
    }

    &.ecs-background-light {
      background: rgba($theme-ecs-background-light, $bluropacity);
    }

    &.ecs-background-dark {
      background: rgba($theme-ecs-background-dark, $bluropacity);
    }

    &.ecs-background-alt {
      background: rgba($theme-ecs-background-alt, $bluropacity);
    }

    &.ecs-background-alt-light {
      background: rgba($theme-ecs-background-alt-light, $bluropacity);
    }

    backdrop-filter: blur(4rem);
  }

  .ecs-box-shadow {
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
  }
}
.ecs-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  @include z-index(page);

  & .ecs-page-background-image {
    @include z-index(background);
    background: none;
    position: absolute;
    top: 10%;
    right: -50%;
    height: 120vmax;
    opacity: 0.7;
  }
}

.ecs-sidemenu {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 25rem;
  @include z-index(box);

  .ecs-sidemenu-actions {
    overflow: unset;
  }

  &.ecs-sidemenu-collapsed {
    flex: 0 0 auto;

    &.ecs-sidemenu-collapsed-xxlarge {
      width: $theme-ecs-rounddimension-xxlarge;
    }

    &.ecs-sidemenu-collapsed-xlarge {
      width: $theme-ecs-rounddimension-xlarge;
    }

    &.ecs-sidemenu-collapsed-large {
      width: $theme-ecs-rounddimension-large;
    }

    &.ecs-sidemenu-collapsed-medium {
      width: $theme-ecs-rounddimension-medium;
    }

    &.ecs-sidemenu-collapsed-small {
      width: $theme-ecs-rounddimension-small;
    }
  }

  .ecs-sidemenu-divider {
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &>div {
      display: flex;
      height: 0;
      border: 0.0675rem solid $dark400;
      opacity: 0.5;
      margin: 0;
    }

    &.ecs-sidemenu-divider-full {
      padding: 0 0.5rem;
      opacity: 0.5;

      &>div {
        flex-grow: 1;
      }
    }

    &.ecs-sidemenu-divider-xlarge {
      width: $theme-ecs-dimension-xlarge;

      &>div {
        width: $theme-ecs-dimension-small;
      }
    }

    &.ecs-sidemenu-divider-large {
      width: $theme-ecs-dimension-large;

      &>div {
        width: $theme-ecs-dimension-xsmall;
      }
    }

    &.ecs-sidemenu-divider-medium {
      width: $theme-ecs-dimension-medium;

      &>div {
        width: $theme-ecs-dimension-xsmall;
      }
    }

    &.ecs-sidemenu-divider-small {
      width: $theme-ecs-dimension-small;

      &>div {
        width: $theme-ecs-dimension-xsmall;
      }
    }
  }
}
.e-grid-container {
  min-width: 30rem;
  min-height: 30rem;
}

.e-grid {
  font-family: 'Avenir Book';
  font-weight: 'normal';

  border-style: none;
  background: none;

  width: 100%;
  max-width: 100%;
  min-width: unset;
  max-height: calc(100% - 96px);

  .e-grid-column-template {
    word-wrap: break-word;
    white-space: break-spaces;
    cursor: pointer;

    .e-grid-summary-link {
      text-decoration: none;
      cursor: pointer;
      width: 100%;

      & > span {
        color: $navy900 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 100%;
      }

      &:hover {
        cursor: pointer;

        & > span {
          color: $theme-primary !important;
          cursor: pointer;
          text-decoration: underline;
        }

        &::after {
          text-decoration: none;
        }
      }
    }

    .e-grid-comment-summary {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      cursor: pointer;

      &:hover {
        color: $theme-primary !important;
        text-decoration: underline;
      }
    }
  }

  .e-gridheader {
    background-color: transparent !important;
    font-family: 'Avenir Medium' !important;
    border-width: 0.0625rem;
    border-radius: 1rem 1rem 0 0;
    border: 1px solid $bley500;

    tr.e-columnheader {
      height: 3rem;
    }

    & * {
      background-color: transparent !important;
    }

    .e-headertext {
      font-family: 'Avenir Black' !important;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.03125rem;
    }

    .e-headercell {
      padding-left: 1rem !important;

      .e-headercelldiv {
        padding-right: 0.5rem;
      }

      .e-rhandler {
        border-right: 2px solid transparent;

        &:hover {
          border-right: 2px solid $dark300;
        }
      }
    }

    th.e-headercell.e-lastcell .e-rhandler {
      border-right-width: 2px !important;
    }
  }

  .e-dialog.e-filter-popup {
    position: absolute;
    top: 43px !important;
    background: $purewhite;
    box-shadow: 0px 0px 74px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: 1px solid $purewhite;

    &:after {
      position: absolute;
      left: 216px;
      top: -14px;
      width: 0;
      height: 0;
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 20px solid $purewhite;
    }

    .e-footer-content {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      padding: 20px 16px 25px;

      .e-btn.e-flmenu-okbtn {
        box-sizing: border-box;
        padding: 0px 16px;
        width: 67px;
        height: 40px;
        background: $aqua900;
        border: 1px solid $aqua900;
        backdrop-filter: blur(5px);
        border-radius: 8px;
        margin-left: 0;
        font-family: 'Avenir Medium';
        font-weight: 900;
        font-size: 14px;
        line-height: 19px;
        color: $purewhite;
      }

      .e-btn.e-flmenu-cancelbtn {
        height: 40px;
        font-family: 'Avenir Medium';
        font-weight: 900;
        font-size: 14px;
        line-height: 19px;
        color: $aqua900;
        background-color: transparent;
        outline: none;
        border: none;
        margin-left: 0;
      }
    }

    .e-dlg-content {
      border-radius: 8px !important;
      padding: 23px 16px 10px;

      &:before {
        content: 'Filter';
        height: 22px;
        font-weight: 900;
        font-size: 16px;
        line-height: 22px;
        color: $navy300;
      }

      .e-flmenu-maindiv {
        margin-top: 12px;
      }

      .e-flmenu-valuediv {
        padding: 16px 0 0;
      }

      .e-popup-flmenu.e-control-wrapper {
        border-radius: 8px;
        padding: 4px;
        color: $bley700;
        font-family: 'Avenir Medium';
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;

        .e-input-group-icon {
          color: $aqua900;
        }
      }
    }
  }

  .e-gridcontent {
    font-family: 'Avenir Book' !important;
    background-color: white !important;
    border-width: 0.0625rem;
    border-radius: 0 0 1rem 1rem;
    border: 1px solid $bley500;

    // could target this with more precision
    & a {
      font-family: 'Avenir Medium' !important;
    }

    .e-row {
      height: 7rem;
    }

    .e-rowcell {
      padding: 1.5rem 1rem;
      vertical-align: top;
      color: $bley700 !important;
      font-size: 1rem;
      font-weight: 400;
      height: 7rem;
      max-height: 7rem;
    }

    td.e-rowcell.e-active {
      background-color: $bley100;
    }

    .e-rowcell-wrap {
      word-wrap: break-word !important;
      white-space: break-spaces !important;
      overflow: hidden;
    }
  }

  &.e-gridhover {
    tr.e-row:hover
      .e-rowcell:not(.e-cellselectionbackground):not(.e-updatedtd):not(
        .e-indentcell
      ),
    tr.e-frozenhover {
      background-color: $bley100 !important;
    }
  }

  & .e-frozenheader > .e-table,
  & .e-frozencontent > .e-table,
  & .e-frozencontent .e-virtualtable > .e-table,
  & .e-frozenheader .e-virtualtable > .e-table {
    border-right-color: $bley500; // customize the frozen table’s border color
  }

  .e-gridpager {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.e-ccdlg.e-popup-open.e-dialog {
  position: absolute;
  top: 0 !important;
  left: auto !important;
  right: 20px;
  background: $purewhite;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 1px solid $purewhite;

  &:after {
    position: absolute;
    left: 216px;
    top: -14px;
    width: 0;
    height: 0;
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid $purewhite;
  }

  .e-footer-content {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    padding: 20px 16px 25px;
    justify-content: space-between;

    .e-btn.e-flat.e-primary,
    .e-css.e-btn.e-flat.e-primary {
      box-sizing: border-box;
      padding: 0px 16px;
      width: 85px;
      height: 40px;
      background: $aqua900;
      border: 1px solid $aqua900;
      backdrop-filter: blur(5px);
      border-radius: 8px;
      margin-left: 0;
      font-family: 'Avenir Medium';
      font-weight: 900;
      font-size: 14px;
      line-height: 19px;
      color: $purewhite;
    }

    .e-btn.e-flat.e-primary {
      text-indent: -9999px;
      line-height: 0;
    }

    .e-btn.e-flat.e-primary:after {
      content: 'Apply';
      text-indent: 0;
      display: block;
      line-height: initial;
    }

    .e-btn.e-flat,
    .e-css.e-btn.e-flat {
      height: 40px;
      font-family: 'Avenir Medium';
      font-weight: 900;
      font-size: 14px;
      line-height: 19px;
      color: $aqua900;
      background-color: transparent;
      outline: none;
      border: none;
      margin-left: 0;
    }
  }

  .e-dlg-content {
    border-radius: 8px !important;
    padding: 23px 16px 10px;

    .e-input-group:not(.e-success):not(.e-warning):not(.e-error) {
      border-color: $bley700;
      border-radius: 5px;
      padding: 5px;
    }

    .e-grid .e-ccdlg .e-cc-searchdiv span.e-ccsearch-icon.e-icons {
      color: $aqua900;
    }

    .e-main-div {
      .e-cc-contentdiv {
        height: auto !important;
      }
    }

    .e-ccul-ele .e-cc:first-child {
      display: none;
    }

    .e-checkbox-wrapper .e-frame.e-stop,
    .e-css.e-checkbox-wrapper .e-frame.e-stop,
    .e-checkbox-wrapper .e-frame,
    .e-css.e-checkbox-wrapper .e-frame {
      border-radius: 5px;
      border-color: $navy100;
      background-color: $navy100;
      color: $purewhite;
    }

    .e-checkbox-wrapper .e-frame,
    .e-css.e-checkbox-wrapper .e-frame {
      background-color: $purewhite;
    }

    .e-checkbox-wrapper .e-frame.e-check,
    .e-css.e-checkbox-wrapper .e-frame.e-check {
      border-radius: 5px;
      border-color: $aqua900;
      background-color: $aqua900;
      color: $purewhite;
    }

    .e-checkbox-wrapper .e-label {
      color: $navy300;
    }

    .e-cc-searchdiv {
      left: 0 !important;

      .e-input-group-icon {
        color: $aqua900;
        font-size: 17px;
      }
    }
  }
}

.ecs-application {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0);
  border: 1px solid transparent;

  transition: box-shadow 0.2s, border 0.2s, background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: $theme-ecs-background-light;

    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid $bley400;
  }

  .ecs-application-sub-pane {
    background-color: transparent !important;
  }
}

.dr-details td {
  padding: 20px 0;
}

.dr-details tr td:first-child {
  font-weight: bold;
  color: $navy900;
  width: 13%;
}

.dr-details tr td,
.dr-details-item {
  font-size: 14px;
  color: $bley700;
}

.dr-details-item span {
  text-align: left;
}
.ecs-application-panel {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  border-radius: 0.75rem !important;

  transition: box-shadow 0.2s, border 0.2s, background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: $theme-ecs-background-light;

    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid $bley400;
  }

  .ecs-application-panel-logo {
    margin-top: 2.5rem;
  }

  .ecs-application-panel-name {
    margin-bottom: 1.375rem;
  }
}

.ecs-application-panel-small {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  border-radius: 0.75rem !important;

  transition: box-shadow 0.2s, border 0.2s, background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: $theme-ecs-background-light;

    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid $bley400;
  }

  .ecs-application-panel-logo {
    margin-top: 1rem;
  }

  .ecs-application-panel-name {
    margin-bottom: 0.75rem;
    font-size: 0.75rem;
  }
}


//ecs styles used for components
//contains global component classes

.ecs-background-none {
  background-color: transparent;
  color: $theme-ecs-clr-dark;
}

.ecs-background-default {
  background-color: $theme-ecs-background;
  color: $theme-ecs-clr-dark;
}

.ecs-background-light {
  background-color: $theme-ecs-background-light;
  color: $theme-ecs-clr-dark;
}

.ecs-background-dark {
  background-color: $theme-ecs-background-dark;
  color: $theme-ecs-clr-light;
}

.ecs-background-alt {
  background-color: $theme-ecs-background-alt;
  color: $theme-ecs-clr-light;
}

.ecs-background-alt-light {
  background-color: $theme-ecs-background-alt-light;
  color: $theme-ecs-clr-dark;
}

.ecs-background-primary {
  background-color: $theme-ecs-primary;
  color: $theme-ecs-clr-light;
}

.ecs-background-secondary {
  background-color: $theme-ecs-secondary;
  color: $theme-ecs-clr-light;
}

.ecs-background-tertiary {
  background-color: $theme-ecs-tertiary;
  color: $theme-ecs-clr-light;
}

.ecs-fill-default {
  fill: $theme-ecs-background;
}

.ecs-fill-light {
  fill: $theme-ecs-background-light;
}

.ecs-fill-dark {
  fill: $theme-ecs-background-dark;
}

.ecs-fill-alt-dark {
  fill: $theme-ecs-background-alt-dark;
}

.ecs-fill-alt {
  fill: $theme-ecs-background-alt;
}

.ecs-fill-alt-light {
  fill: $theme-ecs-background-alt-light;
}

.ecs-rounded-none {
  border-radius: 0;
}

.ecs-rounded-all {
  border-radius: 1rem;
}

.ecs-rounded-top {
  border-radius: 1rem 1rem 0 0;
}

.ecs-rounded-bottom {
  border-radius: 0 0 1rem 1rem;
}

.ecs-rounded-round {
  border-radius: 3vmin;
}

.ecs-padding-none {
  padding: 0;
}

.ecs-padding-xsmall {
  padding: 0.25rem;
}

.ecs-padding-small {
  padding: 0.5rem;
}

.ecs-padding-medium {
  padding: 1rem;
}

.ecs-padding-large {
  padding: 1.5rem;
}

.ecs-padding-xlarge {
  padding: 2rem;
}

.ecs-gap-none {
  gap: 0;
}

.ecs-gap-xsmall {
  gap: 0.25rem;
}

.ecs-gap-small {
  gap: 0.5rem;
}

.ecs-gap-medium {
  gap: 1rem;
}

.ecs-gap-large {
  gap: 1.5rem;
}

.ecs-gap-xlarge {
  gap: 2rem;
}

.ecs-height-xxlarge {
  height: $theme-ecs-dimension-xxlarge;
}

.ecs-height-xlarge {
  height: $theme-ecs-dimension-xlarge;
}

.ecs-height-medium {
  height: $theme-ecs-dimension-xlarge;
}

.ecs-height-large {
  height: $theme-ecs-dimension-large;
}

.ecs-height-small {
  height: $theme-ecs-dimension-small;
}

.ecs-width-xxlarge {
  width: $theme-ecs-dimension-xxlarge;
}

.ecs-width-xlarge {
  width: $theme-ecs-dimension-xlarge;
}

.ecs-width-medium {
  width: $theme-ecs-dimension-xlarge;
}

.ecs-width-large {
  width: $theme-ecs-dimension-large;
}

.ecs-width-small {
  width: $theme-ecs-dimension-small;
}

//experimental
.ecs-line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

//treegrid customization

// .e-treegrid .e-grid {
//   max-height: unset !important;
// }

.e-treegrid .e-gridcontent {
  height: calc(100% - 27px) !important;
}
.e-treegrid .e-headercontent {
  height: 2.5rem !important;
}
.e-treegrid .e-columnheader {
  height: 2.5rem !important;
}

.e-treegrid .e-icons.e-none::before {
  content: '';
}

.e-treegrid .e-icons.e-none {
  width: 0;
  height: 0;
}

.e-treecolumn-container {
  display: flex;
  flex-direction: row;
}

.e-treecell {
  align-self: flex-start;
  margin-right: auto;
}

.e-grid .e-icons:not(.e-btn-icon) {
  color: #000;
}

.e-treegrid .e-treecolumn-container span {
  display: inline-block;
  vertical-align: middle;
}

.e-treegrid .e-treegridexpand,
.e-treegrid .e-treegridcollapse {
  color: #212121;
  cursor: pointer;
  font-size: 14px;
  height: 16px;
  padding: 0;
  text-align: center;
  vertical-align: bottom;
  width: 16px;
}

.e-treegrid .e-treegridexpand {
  transform: rotate(90deg);
}

.e-treegrid .e-treegridexpand::before,
.e-treegrid .e-treegridcollapse::before {
  text-align: center;
  vertical-align: middle;
  padding-left: '1.5rem';
}

.e-treegrid .e-treegridexpand::before,
.e-treegrid .e-treegridcollapse::before {
  content: '\e22f';
}
.e-treegrid .e-grid .e-rowcell {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

@for $i from 1 through 100 {
  $padding: 2rem * $i;
  [class*='level#{$i}'] .e-treecolumn-container {
    padding-left: $padding;
  }
}

[class*='level0'] .e-treecolumn-container {
  padding-left: 0rem;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
  display: none;
}

.e-checkbox-wrapper,
.e-css.e-checkbox-wrapper {
  order: -1;
  margin-right: 1rem;
}


@font-face {
  font-family: "Avenir Roman";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Roman"),
    url("../assets/fonts/Avenir/Avenir_Roman.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Book";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Book"),
    url("../assets/fonts/Avenir/Avenir_Book.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Medium"),
    url("../assets/fonts/Avenir/Avenir_Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Heavy"),
    url("../assets/fonts/Avenir/Avenir_Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Black";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Black"),
    url("../assets/fonts/Avenir/Avenir_Black.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Book";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Book"),
    url("../assets/fonts/Avenir/Avenir_Book.ttf") format("truetype");
}

// Set global fonts
#root {
  font-family: "Avenir Book";
  font-weight: normal;
}

.e-control {
  color: $dark800;
  font-family: "Avenir Medium";
  font-weight: normal;
}

.e-tab .e-toolbar-items .e-toolbar-item .e-tab-text {
  font-family: "Avenir Book";
  font-weight: normal;
}

.e-card {
  font-family: "Avenir Book";
  font-weight: normal;
}
